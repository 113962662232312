import { useRef } from "react";
import { Box, Flex, css, Icon } from "@storyofams/react-ui";
import Image from "next/image";
import Link from "next/link";

import { AddToWishlistRecipe } from "~components/product/components/AddToWishlistRecipe";
import { Text } from "../../../components";
import { Star } from "../Icons";

export const RecipeCard = ({
  content,
  hasWishlistButton = false,
  ...props
}: any) => {
  const contentRef = useRef<HTMLDivElement>();
  const getImageUrl = () => {
    if (content.image.includes("cdn.shopify")) {
      const last = content.image.slice(content.image.lastIndexOf("."));
      const first = content.image.slice(0, content.image.lastIndexOf("."));
      return first + "_600x500" + last;
    }
    if (content?.image?.includes("storyblok"))
      return content?.image + "/m/340x220";
    return content.image;
  };
  return (
    <Link
      prefetch={false}
      href={`/recipes/${content?.slug}`}
      as={`/recipes/${content?.slug}`}
      passHref
    >
      <Box
        {...props}
        as="a"
        // width="100%"
        css={{ "&:hover": { cursor: "pointer" } }}
      >
        <Box
          css={css({
            border: "1px",
            borderColor: ["grey200", "grey200"],
          })}
          borderRadius={"md"}
        >
          <Flex
            borderRadius="inherit"
            flexDirection={["row", "column"]}
            py={["12px", 2]}
            alignItems={"center"}
          >
            <Box px={[0, 2]} width={["max-content", "100%"]}>
              <Box
                height={["60px", "218px"]}
                position="relative"
                width={["60px", "100%"]}
                minWidth={"60px"}
                overflow="hidden"
                borderRadius="md"
                borderBottomLeftRadius={"md"}
                borderBottomRightRadius={"md"}
                ml={["12px", "12px", 0]}
              >
                {(content?.image || content?.thumbnail?.filename) && (
                  <Image
                    alt={content?.thumbnail?.alt || ""}
                    src={getImageUrl()}
                    layout="fill"
                    objectFit="cover"
                  />
                )}
                <Box position={"absolute"} right={1} top={1}>
                  <AddToWishlistRecipe isWishlist id={content.id} />
                </Box>
              </Box>
            </Box>

            <Box mt={[0, 2]} textAlign="left" pr={["12px", 0]} width={"100%"}>
              <Flex
                justifyContent={"space-between"}
                px={[0, 2]}
                ml={["10px", 0]}
              >
                <Text
                  fontFamily={"DINPro"}
                  color="#767473"
                  fontWeight={500}
                  fontSize={"12px"}
                  lineHeight={"22.4px"}
                  css={css({ textTransform: "uppercase" })}
                  letterSpacing={"0.1em"}
                >
                  {"recept"}
                </Text>
                {!!content?.rating && (
                  <Flex>
                    <Text
                      fontWeight={600}
                      fontSize={"16px"}
                      mr={1}
                      lineHeight={"22.4px"}
                      color="#1D1D1B"
                    >
                      {content.rating}
                    </Text>
                    <Icon
                      fontSize={"18px"}
                      color={"oldPink"}
                      pb="2px"
                      icon={Star}
                      lineHeight={"19.6px"}
                    />
                  </Flex>
                )}
              </Flex>

              <Text
                px={[0, 2]}
                ml={["10px", 0]}
                fontSize={["18px", "20px"]}
                lineHeight={"26px"}
                ref={contentRef}
                mt={[0, "6px"]}
                fontWeight={700}
                maxWidth={[props.isShortCard ? "170px" : "64vw", "281px"]}
                css={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {content?.title?.text || content?.title || content?.title_text}
              </Text>
              <Box
                mt={"15px"}
                height="44px"
                width="100%"
                backgroundColor={"#FCF9F7"}
                px={2}
                bg="#FCF9F7"
                fontWeight={500}
                lineHeight={"16px"}
                borderY="1px solid grey200"
                alignItems="center"
                whiteSpace={"nowrap"}
                display={["none", "none", "flex"]}
                justifyContent={"space-between"}
                p="12px 16px"
                fontFamily={"DINPro"}
                fontSize="12px"
                css={{ textTransform: "uppercase" }}
                letterSpacing={"0.1em"}
              >
                <Text>{content?.difficulty_text}</Text>
                <Text>{content?.total_time} MIN.</Text>
                <Text overflow="hidden" css={{ textOverflow: "clip" }}>
                  {`${
                    content.ingredients
                      ? content.ingredients.split(",")?.length
                      : content?.recipes_ingredients?.length
                  }
                     INGREDIENTEN`}
                </Text>
              </Box>
              <Box display={["none", "block"]}>
                <Text
                  px={2}
                  color="#1D1D1BCC"
                  style={{
                    display: "-webkit-box",
                    //@ts-ignore
                    "-webkit-line-clamp": "3",
                    "-webkit-box-orient": "vertical",
                    overflow: "hidden",
                  }}
                  lineHeight={"22.4px"}
                  minHeight={"67.22px"}
                  mt={"15px"}
                >
                  {content?.summary_text.replace(/<\/?[^>]+(>|$)/g, " ")}
                </Text>
              </Box>
              <Box
                alignItems={"center"}
                mt={2}
                px={2}
                display={["none", "flex"]}
                height={"26px"}
              >
                {content?.author_profile_picture && (
                  <Box
                    mr="8px"
                    width={"28px"}
                    overflow={"hidden"}
                    height={"28px"}
                    position={"relative"}
                    borderRadius={"full"}
                    border="1px solid #E2DFDF"
                  >
                    <Image
                      objectFit="cover"
                      layout="fill"
                      src={content.author_profile_picture}
                    />
                  </Box>
                )}
                <Text
                  css={{ textTransform: "uppercase" }}
                  fontFamily={"DINPro"}
                  fontSize={"12px"}
                  letterSpacing={"0.1em"}
                  color="#767473"
                  pt="2px"
                  fontWeight={500}
                >
                  recipe by {content?.author}
                </Text>
              </Box>
            </Box>
          </Flex>
          <Box
            height="44px"
            width="100%"
            backgroundColor={"#FCF9F7"}
            px={2}
            bg="#FCF9F7"
            fontWeight={500}
            lineHeight={"16px"}
            borderTop="1px solid grey200"
            alignItems="center"
            whiteSpace={"nowrap"}
            display={["flex", "flex", "none"]}
            justifyContent={"space-between"}
            p="12px"
            fontFamily={"DINPro"}
            borderBottomRightRadius={"inherit"}
            borderBottomLeftRadius={"inherit"}
            fontSize="12px"
            css={{ textTransform: "uppercase" }}
            letterSpacing={"0.1em"}
          >
            <Text>{content?.difficulty_text}</Text>
            <Text>{content?.total_time} MIN.</Text>
            <Text overflow="hidden" css={{ textOverflow: "clip" }}>
              {`${
                content.ingredients
                  ? content.ingredients.split(",")?.length
                  : content?.recipes_ingredients?.length
              }
                     INGREDIENTEN`}
            </Text>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
