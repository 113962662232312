import { Icon } from "@storyofams/react-ui";
import { AnimatePresence, motion } from "framer-motion";
import { usePostHog } from "posthog-js/react";
import styled from "styled-components";

import { useWishlist } from "~context";
import { Button } from "../../../components";
import { Heart, HeartOutline } from "../../../components/common/Icons";

const spring = {
  type: "spring",
  damping: 10,
  stiffness: 100,
};

const Wrapper = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AddToWishlistRecipe = ({ id, isWishlist = false }) => {
  const posthog = usePostHog();
  const { recipesWishlist, addOrRemoveFromRecipesWishlist } = useWishlist();
  const isInWishlist: boolean = recipesWishlist && recipesWishlist.includes(id);
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        // posthog.capture("Added to wishlist recipe collection");
        addOrRemoveFromRecipesWishlist(isInWishlist ? "remove" : "add", id);
      }}
      variant="unstyled"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2.5}
      size={isWishlist ? 42 : 57}
      bg={[isWishlist ? "none" : "white", "white"]}
      border={[isWishlist ? "none" : "1px", "1px"]}
      borderColor={[
        isWishlist ? "none" : isInWishlist ? "oldPink" : "grey200",
        isInWishlist ? "oldPink" : "grey200",
      ]}
      borderRadius="md"
      ariaLabel={isInWishlist ? "Remove from wishlist" : "Add to wishlist"}
    >
      <AnimatePresence>
        {isInWishlist && (
          <Wrapper
            initial={{ opacity: 0, transform: "scale(0)" }}
            exit={{ opacity: 0, transform: "scale(0)" }}
            animate={{ opacity: 1, transform: "scale(1)" }}
            transition={spring}
          >
            <Icon
              icon={Heart}
              fontSize={[
                isWishlist ? "20px" : "16px",
                isWishlist ? "16px" : "20px",
              ]}
              color={[isWishlist ? "white" : "oldPink", "oldPink"]}
            />
          </Wrapper>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!isInWishlist && (
          <Wrapper
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.24 }}
          >
            <Icon
              icon={HeartOutline}
              fontSize={["16px", "20px"]}
              color="grey200"
            />
          </Wrapper>
        )}
      </AnimatePresence>
    </Button>
  );
};
